import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import {Nav, Button, Row, Col, UncontrolledDropdown, DropdownToggle, InputGroup, InputGroupAddon, InputGroupText, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input,Table} from 'reactstrap';
import Sidebar from './layout-components/sidebar/sidebar';
import { functions, functionsEU, rtdb } from '../components/firebase/firebase';
import ThemeRoutes from '../routes/router';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import ExportStats from '../components/stats/exportstats';


@inject('observableStore')
@observer

class Fulllayout extends React.Component {
	constructor(props) {
		super(props);
		this.updateDimensions = this.updateDimensions.bind(this);
		this.state = {
			isOpen: false,
			width: window.innerWidth,
			activeSidebarType: "full",
			isOpen: false,
			modal: false,
			accModal: false,
			publishModal: false,
			publishEventModal: false,
			publishStatus: '',
			publishAll: false,
			publishDetails: false,
			publishBarren: false,
			publishOplaadpunten: false,
			publishMenu: false,
			publishMenuInd: 0,
			infoModal: false,
			publishInd: 0,
			currPublishMenu: 0,
			currPublishCat: 0,
			newCatName: '',
			activeSidebarType: "full",
			newEventName: '',
			newOrganisorName: '',
			newUserState: 1,
			newOrganiserMail: '',
			newOrganiserPass: '',
			newOrganiserRole: 'admin',
			newScannerEvt: '',
			createUserStatus: 'wait',
			incassoModal: false,
			incassoInfo: {},
			selectedEvent: '',
			dropdownOpen: false,
			delModal: false
		};

		this.props.history.listen((location, action) => {
			if (window.innerWidth < 767 &&
				document.getElementById('main-wrapper').className.indexOf("show-sidebar") !== -1) {
				document.getElementById('main-wrapper').classList.toggle("show-sidebar");
			}
		});
	}
	
	toggle() {this.setState({isOpen: !this.state.isOpen})}	
	toggleModal = (dublicate) => {if(dublicate && this.state.selectedEvent === ''){alert('Selecteer eerst een evenement')}else{this.setState({modal: !this.state.modal, dublicate: dublicate})}}	
	toggleaccModal = () => {this.setState({accModal: !this.state.accModal, createUserStatus: 'wait'})}	
	togglePublishModal = (ind) => {this.setState({publishModal: !this.state.publishModal, publishInd: Number.isInteger(ind) ? ind : 0, currPublishMenu: 0})}	
	togglePublishEventModal = (ind) => {this.setState({publishEventModal: !this.state.publishEventModal, publishStatus: '', publishDetails: false, publishBarren: false, publishOplaadpunten: false, publishMenu: false, publishMenuInd: 0})}
	toggleInfoModal = (error) => this.setState({infoModal: !this.state.infoModal, infoModalTxt: error});
	toggleDropdown = () => {this.setState({dropdownOpen: !this.state.dropdownOpen})}
	toggleDelModal = () => {
		if(this.state.selectedEvent === ''){alert('Selecteer eerst een evenement'); return null}
		if(!this.props.observableStore.isAdmin){this.toggleInfoModal('Je hebt geen bevoegdheden om evenementen te verwijderen'); return null}
		this.setState({delModal: !this.state.delModal})
	}
	
	toggleIncassoModal = (info) => {
		if(!this.state.incassoModal){this.setState({incassoModal: true, incassoInfo: info})}
		else{this.setState({incassoModal: false, incassoInfo: {}})}
	}
	
	saveNewUserInfo = () => {	
		if(this.state.newUserState === 1){
			if(this.state.newOrganisorName !== '')
			{			
				this.setState({newUserState: 2});	
			}
		}
		if(this.state.newUserState === 2){
			if(this.state.newEventName !== ''){	
				this.props.observableStore.setFirstOrganisatie(this.state.newOrganisorName);
				this.props.observableStore.createEvent(this.state.newEventName);
				this.awaitNewEventInfo();
			}
		}
	}
	
	awaitNewEventInfo = () => {
		if(this.props.observableStore.currEvent.naam === this.state.newEventName){
			this.props.history.push("/");
		}
		else{
			setTimeout(this.awaitNewEventInfo, 250);
		}		
	}

	addEvent = () => {
		if(this.state.newEventName !== ''){
			if(this.state.dublicate){
				this.props.observableStore.dublicateEvent(this.state.selectedEvent, this.state.newEventName);
				this.setState({selectedEvent: ''});
			}
			else{this.props.observableStore.createEvent(this.state.newEventName)}
			this.toggleModal();
		}
	}	

	delEvent = () => {
		this.props.observableStore.deleteEvent(this.state.selectedEvent);
		this.setState({selectedEvent: ''});
		this.toggleDelModal();
	}	
	
	addOrganiser = () => {
		if(this.re.test(String(this.state.accMail).toLowerCase())){
			this.setState({'createUserStatus': 'process'});
			var createAcc = functions.httpsCallable('createAcc');
			createAcc({eid: this.props.observableStore.currEvent.ID, mail: this.state.accMail, role: this.state.newOrganiserRole}).then(result => {
				if(result.data.text === 'succes'){
					this.setState({'createUserStatus': 'succes', newPass: result.data.pass});
				}
				else{
					this.setState({'createUserStatus': 'failed'});
				}
			}).catch(err => this.setState({'createUserStatus': 'failed'}));
		}
		else{
			alert('Voer a.u.b. een valide email address in');
		}
	}
	
	requestPublish(){
		var titel = 'Aanvraag publicatie evenement: ' + this.props.observableStore.currEvent.naam;
		var message = 'Organisatie ' + this.props.observableStore.userInfo.bedrijfsnaam + ' (' + this.props.observableStore.uid + ') wilt het volgende evenement publiceren: ' + this.props.observableStore.currEvent.naam + ' (' + this.props.observableStore.currEvent.ID + ')';
		var sendMail = functions.httpsCallable('sendMail');
		sendMail({mailType: 'contact', titel: titel, reciever: 'info@partypay.nl', afzenderMail: this.props.observableStore.mail, afzenderNaam: this.props.observableStore.userInfo.bedrijfsnaam, aafzenderTel: 'n.v.t.', afzenderBericht: message});
		this.togglePublishEventModal();
		this.toggleInfoModal('Aanvraag verstuurd en zal zo spoedig mogelijk worden beoordeeld');
	}
	
	publishAll(){
		this.setState({		
			publishAll: !this.state.publishAll,
			publishDetails: !this.state.publishAll,
			publishBarren: !this.state.publishAll,
			publishOplaadpunten: !this.state.publishAll,
			publishLockers: !this.state.publishAll,
			publishMenu: !this.state.publishAll,
		})
	}
	
	re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

	formatDate(tijd){
		var date = new Date(0);
		date.setUTCSeconds(tijd.seconds);
		return ("0" + date.getDate()).slice(-2) + '-' + ("0" + (date.getMonth()+1)).slice(-2) + '-' + date.getFullYear();
	}

	formatDate2(tijd){
		if(tijd && tijd === parseInt(tijd, 10)){
			var date = new Date(0);
			date.setUTCSeconds(tijd);
			return ("0" + date.getDate()).slice(-2) + '-' + ("0" + (date.getMonth()+1)).slice(-2) + '-' + date.getFullYear();
		}
		return null;
	}
	
	delNotification(ind){
		var newNotifications = this.props.observableStore.currEvent.notifications.reverse();
		newNotifications.splice(ind, 1);
		this.props.observableStore.currEvent.notifications = newNotifications.reverse();
		this.props.observableStore.saveEventInfo();
	}
	
	replaceMenuCat(){
		this.props.observableStore.currEvent.menus[this.state.currPublishMenu].menu[this.state.currPublishCat].items = this.props.observableStore.currEvent.notifications.reverse()[this.state.publishInd].data;
		this.props.observableStore.currMenu = parseFloat(this.state.currPublishMenu);
		this.props.observableStore.currMenuGroep = parseFloat(this.state.currPublishCat);
		this.togglePublishModal();		
		this.props.history.push("/event-menu");
		this.toggleInfoModal('Controleer de aanpassingen en druk indien gewenst op "Opslaan".');	
		this.delNotification(this.state.publishInd);
	}
	
	newMenuCat(){
		var newMenu = {id: this.getUniqueID('cat'), name: this.state.newCatName, group: 'n.v.t.', status: true, items: this.props.observableStore.currEvent.notifications.reverse()[this.state.publishInd].data};
		this.props.observableStore.currEvent.menus[this.state.currPublishMenu].menu.push(newMenu);
		this.props.observableStore.currMenu = parseFloat(this.state.currPublishMenu);
		this.props.observableStore.currMenuGroep = this.props.observableStore.currEvent.menus[this.state.currPublishMenu].menu.length -1;
		this.togglePublishModal();		
		this.props.history.push("/event-menu");
		this.toggleInfoModal('Controleer de aanpassingen en druk indien gewenst op "Opslaan".');
		this.delNotification(this.state.publishInd);
	}
	
	UID = 1;	
	getUniqueID(type){
		var UIDArr = this.props.observableStore.currEvent.menus[this.state.currPublishMenu].menu;
		for (var i = 0; i < UIDArr.length; i++) {
			if(this.UID === UIDArr[i].id){
				this.UID = this.UID + 1;
				return this.getUniqueID(type);
			}
		}
		var newUID = this.UID;
		this.UID = 1;
		return newUID;
	}

	processIncasso = 0;

	sendIncasso(ind, incassoDoc){
		if(this.props.observableStore.isAdmin && this.processIncasso === 0){
			this.processIncasso = 1;
			var handleInc = functionsEU.httpsCallable('sendIncasso'); 
			handleInc({
				eid: this.props.observableStore.currEvent.ID, 
				naam: this.props.observableStore.currEvent.naam, 
				paymentMethods: this.props.observableStore.currEvent.paymentMethods, 
				muntprijs: this.props.observableStore.currEvent.muntprijs, 
				pay: this.props.observableStore.currEvent.pay, 
				oid: this.props.observableStore.currEvent.oid, 
				incassoDoc: incassoDoc
			});
			var newNotifications = this.props.observableStore.currEvent.notifications.reverse();
			newNotifications[ind].button = false;
			this.props.observableStore.currEvent.notifications = newNotifications.reverse();
			this.props.observableStore.saveEventInfo();
			setTimeout(() => {this.processIncasso = 0}, 1000);
		}
		else{
			this.toggleInfoModal('Incasso opdrachten versturen is niet toegestaan op deze account. Neem contact op met je account manager.');
		}
	}
	
	showMobilemenu() {
		document.getElementById('main-wrapper').classList.toggle('show-sidebar');
	}
	
	sidebarHandler = () => {
		let element = document.getElementById('main-wrapper');
		switch (this.state.activeSidebarType) {
			case 'full':
			case 'iconbar':
				element.classList.toggle('mini-sidebar');
				if (element.classList.contains('mini-sidebar')) {
					element.setAttribute('data-sidebartype', 'mini-sidebar');					
				} else {
					element.setAttribute(
						'data-sidebartype',
						this.state.activeSidebarType
					);					
				}
				break;

			case 'overlay':
			case 'mini-sidebar':
				element.classList.toggle('full');
				if (element.classList.contains('full')) {
					element.setAttribute('data-sidebartype', 'full');					
				} else {
					element.setAttribute(
						'data-sidebartype',
						this.state.activeSidebarType
					);					
				}
				break;
			default:
		}
	};
	/*--------------------------------------------------------------------------------*/
	/*Life Cycle Hook, Applies when loading or resizing App                           */
	/*--------------------------------------------------------------------------------*/
	componentDidMount() {
		window.addEventListener("load", this.updateDimensions());
		window.addEventListener("resize", this.updateDimensions());
	}
	/*--------------------------------------------------------------------------------*/
	/*Function that handles sidebar, changes when resizing App                        */
	/*--------------------------------------------------------------------------------*/
	updateDimensions() {
		let element = document.getElementById('main-wrapper');
		this.setState({
			width: window.innerWidth
		});
		switch (this.state.activeSidebarType) {
			case 'full':
			case 'iconbar':
				if (this.state.width < 2500) {
					element.setAttribute("data-sidebartype", "mini-sidebar");
					element.classList.add("mini-sidebar");
				} else {
					element.setAttribute("data-sidebartype", this.state.activeSidebarType);
					element.classList.remove("mini-sidebar");
				}
				break;

			case 'overlay':
				if (this.state.width < 767) {
					element.setAttribute("data-sidebartype", "mini-sidebar");
				} else {
					element.setAttribute("data-sidebartype", this.state.activeSidebarType);
				}
				break;

			default:
		}
	}
	/*--------------------------------------------------------------------------------*/
	/*Life Cycle Hook                                                                 */
	/*--------------------------------------------------------------------------------*/
	componentWillUnmount() {
		window.removeEventListener("load", this.updateDimensions);
		window.removeEventListener("resize", this.updateDimensions);
	}
	render() {
		/*--------------------------------------------------------------------------------*/
		/* Theme Setting && Layout Options wiil be Change From Here                       */
		/*--------------------------------------------------------------------------------*/
		return (
			<div
				id="main-wrapper"
				dir={"ltr"}
				data-theme={"light"}
				data-layout={"vertical"}
				data-sidebartype={this.state.activeSidebarType}
				data-sidebar-position={"fixed"}
				data-header-position={"fixed"}
				data-boxed-layout={"full"}
			>
				{/*--------------------------------------------------------------------------------*/}
				{/* Sidebar                                                                        */}
				{/*--------------------------------------------------------------------------------*/}
				<Sidebar {...this.props} routes={ThemeRoutes} />
				{/*--------------------------------------------------------------------------------*/}
				{/* Page Main-Content                                                              */}
				{/*--------------------------------------------------------------------------------*/}
				<div className="page-wrapper d-block">
					<ExportStats ref={ref => this.exportRef = ref} />
					<div style={{width: '100%', zIndex: 10}} className="mainHead">
						<div style={{display: 'flex'}}>
							<Nav navbar>
								<div onClick={() => {this.showMobilemenu()}} className="mobileMenu" style={{paddingLeft: 30, display: 'none'}}><i className="fa-light fa-bars" style={{fontSize: 24, marginTop: 15, marginLeft: -15, paddingRight: 25}}/></div>
							</Nav>
							<Nav className="ml-auto" style={{paddingBottom: 20}} navbar>
								<div style={{display: 'flex', justifyContent: 'flex-end'}}>								
									{this.props.observableStore.isAdmin === true ?
									<div style={{marginRight: 20, paddingTop: 5}}>	
										<UncontrolledDropdown nav inNavbar>
											<DropdownToggle nav style={{display: 'flex', color: '#212529', alignItems: 'center', justifyContent: 'flex-end'}}>{this.props.observableStore.userInfo.bedrijfsnaam ? this.props.observableStore.userInfo.bedrijfsnaam : "Organisatie"} <i className="fa fa-angle-down" style={{marginLeft: 7}}/></DropdownToggle>
											<DropdownMenu>
												<InputGroup style={{padding: 20, paddingTop: 10, paddingBottom: 10}}>
													<InputGroupAddon addonType="prepend">
														<InputGroupText style={{background: '#fff'}}><i style={{color: 'rgba(0,0,0,0.4)'}} class="fa fa-magnifying-glass"></i></InputGroupText>
													</InputGroupAddon>
													<Input style={{borderLeftWidth: 0, paddingLeft: 0}} type="text" className="FullColorPlaceholder" placeholder="Zoek op naam" value={this.state.searchInput} onChange={e => this.setState({searchInput: e.target.value})}/>
												</InputGroup>
												{
													this.props.observableStore.organisorList.filter(e => !this.state.searchInput || this.state.searchInput === '' || e.bedrijfsnaam.toLowerCase().includes(this.state.searchInput)).map((evt, key) => {	
														return <DropdownItem style={{cursor: 'pointer'}} onClick={ () => {this.props.observableStore.setOrganisor(this.props.observableStore.organisorList.findIndex(e => e.bedrijfsnaam === evt.bedrijfsnaam));  this.props.history.push("/"); this.setState({searchInput: '', selectedEvent: '', dropdownOpen: false})}}>{evt.bedrijfsnaam}</DropdownItem>
													})
												}
												<div style={{padding: 20, paddingTop: 10, paddingBottom: 10}}><DropdownItem divider /></div>
												<DropdownItem onClick={this.toggleaccModal} style={{cursor: 'pointer', paddingRight: 50}}><i class="fa fa-plus" style={{marginRight: 10}}></i>Nieuwe account</DropdownItem>
											</DropdownMenu>
										</UncontrolledDropdown>
									</div>
									: null}	
									
									{this.props.observableStore.eventIndex.length > 0 ?
									<div style={{paddingTop: 5, paddingRight: 10}}>	
										<UncontrolledDropdown nav inNavbar isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
											<DropdownToggle nav style={{display: 'flex', color: '#212529', alignItems: 'center', justifyContent: 'flex-end'}}>
												{this.props.observableStore.currEvent.cover ?
													<div style={{textAlign: 'end', marginRight: 10, marginTop: -4, width: 25, height: 25, borderRadius: 12.5, overflow: 'hidden'}}>
														<img src={this.props.observableStore.currEvent.cover} alt="" key={this.props.observableStore.currEvent.cover} width="50" height='28' />
													</div>
												:null}
												{this.props.observableStore.currEvent.naam ? this.props.observableStore.currEvent.naam : "Evenement"} <i className="fa fa-angle-down" style={{marginLeft: 7}}/>
											</DropdownToggle>
											<DropdownMenu>
												<InputGroup style={{padding: 20, paddingTop: 10, paddingBottom: 10}}>
													<InputGroupAddon addonType="prepend">
														<InputGroupText style={{background: '#fff'}}><i style={{color: 'rgba(0,0,0,0.4)'}} class="fa fa-magnifying-glass"></i></InputGroupText>
													</InputGroupAddon>
													<Input style={{borderLeftWidth: 0, paddingLeft: 0}} type="text" className="FullColorPlaceholder" placeholder="Zoek op event" value={this.state.searchInput2} onChange={e => this.setState({searchInput2: e.target.value})}/>
												</InputGroup>
												<div style={{maxHeight: 340, overflow: 'scroll'}}>
													{this.props.observableStore.eventIndex.filter(e => !this.state.searchInput2 || this.state.searchInput2 === '' || e.naam.toLowerCase().includes(this.state.searchInput2)).sort((a, b) => b.tijd - a.tijd).map((evt, key) => (	
														<DropdownItem toggle={false} style={{cursor: 'pointer', display: 'flex'}}>
															<div onClick={() => this.setState({selectedEvent: this.state.selectedEvent === evt.eid ? '' : evt.eid, selectedEventName: evt.naam})} style={{cursor: 'pointer', marginTop: 2}}>
																{this.state.selectedEvent === evt.eid ?
																<div style={{width: 18, height: 18, borderWidth: 1, borderStyle: 'solid', borderColor: '#4cc15f', borderRadius: 4, marginRight: 10}}>
																	<i class="fa fa-check" style={{color: '#4cc15f', padding: 2, fontSize: 12, position: 'absolute'}}></i>
																</div>
																:
																<div style={{width: 18, height: 18, borderWidth: 1, borderStyle: 'solid', borderColor: 'rgba(0,0,0,0.2)', borderRadius: 4, marginRight: 10}}></div>
																}
															</div>
															<div onClick={ () => {this.props.observableStore.setCurrEvent(evt.eid); this.props.history.push("/"); this.setState({searchInput2: '', selectedEvent: '', dropdownOpen: false})}}>
																{this.formatDate2(evt.tijd) ? <p style={{color: 'rgba(0,0,0,0.5)', display: 'inline', paddingRight: 8}}>{this.formatDate2(evt.tijd)}</p>:null} 
																{evt.naam}
															</div>
														</DropdownItem>
													))}
												</div>
												<div style={{padding: 20, paddingTop: 10, paddingBottom: 10}}><DropdownItem divider /></div>
												<DropdownItem onClick={() => this.toggleModal()} style={{cursor: 'pointer'}}><i class="fa fa-plus" style={{marginRight: 10}}></i>Nieuw evenement aanmaken</DropdownItem>
												<DropdownItem onClick={() => this.toggleModal(true)} style={{cursor: 'pointer'}}><i className="fa fa-copy" style={{marginRight: 10}}></i>Dubliceer evenement</DropdownItem>
												<DropdownItem onClick={() => this.toggleDelModal()} style={{cursor: 'pointer'}}><i className="fa fa-trash" style={{marginRight: 10}}></i>Evenement verwijderen</DropdownItem>
											</DropdownMenu>
										</UncontrolledDropdown>										
									</div>
									:null}									
									
									<UncontrolledDropdown nav inNavbar>
									<DropdownToggle nav style={{display: 'flex', color: '#212529', marginLeft: 15, alignItems: 'center', justifyContent: 'flex-end'}}>
										{this.props.observableStore.currEvent.publishNotf ?
										<div onClick={() => this.togglePublishEventModal()} style={{cursor: 'pointer', marginTop: 2, textAlign: 'end', position: 'relative'}}>
											{this.props.observableStore.currEvent.publishNotf.afhaal || this.props.observableStore.currEvent.publishNotf.oplaad || this.props.observableStore.currEvent.publishNotf.menu || this.props.observableStore.currEvent.publishNotf.settings || this.props.observableStore.currEvent.publishNotf.lockers ?
												<div style={{width: 6, height: 6, borderRadius: 3, backgroundColor: '#d12a5e', position: 'absolute', top: -2, right: -1}}></div>
											:null}
											<i class="fa-regular fa-file-export" style={{color: 'rgba(0,0,0,0.3)', marginLeft: 1, fontSize: 19}}></i>
										</div>
										:null}
									</DropdownToggle>
									</UncontrolledDropdown>
									
									<UncontrolledDropdown nav inNavbar>
										<DropdownToggle nav style={{display: 'flex', color: '#212529', marginLeft: 18, marginRight: 15, paddingRight: 10, alignItems: 'center', justifyContent: 'flex-end'}}>
											<div style={{cursor: 'pointer', marginTop: 2, textAlign: 'end', position: 'relative'}}>
												{this.props.observableStore.currEvent.notifications && this.props.observableStore.currEvent.notifications.length > 0 ?
												<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: 15, height: 15, borderRadius: 7.5, backgroundColor: '#d12a5e', color: '#fff',position: 'absolute', top: -8, right: -7, fontSize: 9, paddingTop: 2}}>{this.props.observableStore.currEvent.notifications.length}</div>
												:null}
												<i class="fa-regular fa-bell" style={{color: 'rgba(0,0,0,0.3)', fontSize: 20}}></i>
											</div>
										</DropdownToggle>
										{this.props.observableStore.currEvent.notifications && this.props.observableStore.currEvent.notifications.length > 0 ?
										<DropdownMenu>
										    {this.props.observableStore.currEvent.notifications && this.props.observableStore.currEvent.notifications.reverse().map((notf, key) => {
												if(notf.type === 'menu'){
													return <div style={{display: 'flex', padding: '0px 25px 20px 25px', width: 350, borderWidth: 0, borderBottomWidth: key === (this.props.observableStore.currEvent.notifications.length -1) ? 0 : 1, borderStyle: 'solid', borderColor: 'rgba(0,0,0,0.06)'}}>
														<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
															<div style={{textAlign: 'end', display: 'flex', position: 'relative', justifyContent: 'center', alignItems: 'center', width: 25, height: 25, borderRadius: 12.5, borderStyle: 'solid', borderWidth: 1, borderColor: 'rgba(0,0,0,0.6)'}}>
																<i class="fa-regular fa-bell" style={{color: 'rgba(0,0,0,0.6)',}}></i>
															</div>
														</div>
														<div style={{marginLeft: 20,}}>
															<div style={{padding: '15px 0px 12px 0px'}}>
																{notf.naam} wil een menu categorie publiceren/updaten
															</div>
															<div style={{display: 'flex', justifyContent: 'space-between'}}>
																<div style={{display: 'flex', alignItems: 'center', color: 'rgba(0,0,0,0.5)', fontSize: 12}}>
																	<i class="fa-regular fa-calendar-day" style={{color: 'rgba(0,0,0,0.4)', marginRight: 6, marginTop: -1}}></i>
																	{this.formatDate(notf.date)}
																</div>
																<div style={{display: 'flex'}}>
																	<div onClick={() => this.togglePublishModal(key)} style={{fontSize: 10, padding: '4px 6px 2px 6px', backgroundColor: '#d12a5e', borderRadius: 7, cursor: 'pointer', color: '#fff'}}><i class="fa fa-check" style={{color: '#fff'}}></i></div>																	
																	<div onClick={() => this.delNotification(key)} style={{marginLeft: 10, fontSize: 11, padding: '5px 8px 4px 8px', backgroundColor: 'rgba(0,0,0,0.1)', borderRadius: 7, cursor: 'pointer'}}><i class="fa fa-times" style={{color: 'rgba(0,0,0,0.6)'}}></i></div>
																</div>
															</div>
														</div>
													</div>
												}
												if(notf.type === 'storno'){
													return <div style={{display: 'flex', padding: '0px 25px 20px 25px', width: 390, borderWidth: 0, borderBottomWidth: key === (this.props.observableStore.currEvent.notifications.length -1) ? 0 : 1, borderStyle: 'solid', borderColor: 'rgba(0,0,0,0.06)'}}>
														<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
															<div style={{textAlign: 'end', display: 'flex', position: 'relative', justifyContent: 'center', alignItems: 'center', width: 25, height: 25, borderRadius: 12.5, borderStyle: 'solid', borderWidth: 1, borderColor: 'rgba(0,0,0,0.6)'}}>
																<i class="fa fa-bell" style={{color: 'rgba(0,0,0,0.6)',}}></i>
															</div>
														</div>
														<div style={{marginLeft: 20,}}>
															<div style={{padding: '15px 0px 12px 0px'}}>Er is een afschrijving mislukt of geweigert. Klik hieronder voor meer details.</div>
															<div style={{display: 'flex', justifyContent: 'space-between'}}>
																<div style={{display: 'flex', alignItems: 'center', color: 'rgba(0,0,0,0.5)', fontSize: 12}}>
																	<i class="fa-regular fa-calendar-day" style={{color: 'rgba(0,0,0,0.4)', marginRight: 6, marginTop: -1}}></i>
																	{this.formatDate(notf.date)}
																</div>
																<div onClick={() => this.toggleIncassoModal(notf.info)} style={{fontSize: 12, padding: '4px 8px', backgroundColor: '#d12a5e', borderRadius: 7, cursor: 'pointer', color: '#fff'}}>Meer details</div>																	
																<div onClick={() => this.delNotification(key)} style={{fontSize: 11, padding: '5px 8px 4px 8px', backgroundColor: 'rgba(0,0,0,0.1)', borderRadius: 7, cursor: 'pointer'}}><i class="fa fa-times" style={{color: 'rgba(0,0,0,0.6)'}}></i></div>
															</div>
														</div>
													</div>
												}
												if(notf.type === 'incasso'){
													return <div style={{display: 'flex', padding: '0px 25px 20px 25px', width: 390, borderWidth: 0, borderBottomWidth: key === (this.props.observableStore.currEvent.notifications.length -1) ? 0 : 1, borderStyle: 'solid', borderColor: 'rgba(0,0,0,0.06)'}}>
														<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
															<div style={{textAlign: 'end', display: 'flex', position: 'relative', justifyContent: 'center', alignItems: 'center', width: 25, height: 25, borderRadius: 12.5, borderStyle: 'solid', borderWidth: 1, borderColor: 'rgba(0,0,0,0.6)'}}>
																<i class="fa fa-bell" style={{color: 'rgba(0,0,0,0.6)',}}></i>
															</div>
														</div>
														<div style={{marginLeft: 20,}}>
															<div style={{padding: '15px 0px 12px 0px'}}>{notf.aantal > 1 ? 'Er zijn ' + notf.aantal + ' nieuwe bezoekers met een negatief saldo.' : 'Er is 1 nieuwe bezoeker met een negatief saldo'}</div>
															<div style={{display: 'flex', justifyContent: 'space-between'}}>
																<div style={{display: 'flex', alignItems: 'center', color: 'rgba(0,0,0,0.5)', fontSize: 12}}>
																	<i class="fa-regular fa-calendar-day" style={{color: 'rgba(0,0,0,0.4)', marginRight: 6, marginTop: -1}}></i>
																	{this.formatDate(notf.date)}
																</div>
																{notf.button ? 
																<div onClick={() => this.sendIncasso(key, notf.incassoDoc)} style={{fontSize: 12, padding: '4px 10px', backgroundColor: '#d12a5e', borderRadius: 7, cursor: 'pointer', color: '#fff'}}>Verstuur incasso</div> 
																:
																<div style={{fontSize: 12, padding: '4px 10px', backgroundColor: 'rgb(67, 160, 71)', borderRadius: 7, color: '#fff'}}>Incasso's verstuurd</div> 
																}																	
																<div onClick={() => this.delNotification(key)} style={{fontSize: 11, padding: '5px 8px 4px 8px', backgroundColor: 'rgba(0,0,0,0.1)', borderRadius: 7, cursor: 'pointer'}}><i class="fa fa-times" style={{color: 'rgba(0,0,0,0.6)'}}></i></div>
															</div>
														</div>
													</div>
												}
											})}
										</DropdownMenu>
										:null}
									</UncontrolledDropdown>	
									
								</div>
							</Nav>
							<Modal isOpen={this.state.modal} toggle={() => this.toggleModal()} className={this.props.className} centered >
								<ModalHeader toggle={() => this.toggleModal()}>Evenement toevoegen</ModalHeader>
								<ModalBody>
									<FormGroup>
										<Label htmlFor="example-email">Evenement naam</Label>
										<Input type="text" id="example-email" name="name" placeholder="Bijv: Partypay festival 2020" onChange={e => this.setState({newEventName: e.target.value})}/>
									</FormGroup>
								</ModalBody>
								<ModalFooter>
									<Button style={{backgroundColor: '#d12a5e', border: 0}} onClick={() => this.addEvent()}>
									  Toevoegen
									</Button>
									<Button className="cancel_btn" onClick={() => this.toggleModal()}>
									  Annuleren
									</Button>
								</ModalFooter>
							</Modal>
							
							<Modal isOpen={this.state.accModal} toggle={this.toggleaccModal} className={this.props.className} centered >
								<ModalHeader toggle={this.toggleaccModal}>Account {this.state.editAcc ? 'aanpassen' : 'toevoegen'}</ModalHeader>								
								{this.state.createUserStatus === 'wait' ?
									<ModalBody>
										<Row>
											<Col sm={6}>
												<FormGroup>
													<Label htmlFor="example-email">Email adress:</Label>
													<Input type="text" style={{height: 40}} id="example-email" value={this.state.accMail} name="name" onChange={e => this.setState({accMail: e.target.value})}/>
												</FormGroup>
											</Col>
											<Col sm={6}>
												<Label htmlFor="example-email">Type account</Label>
												<Input type="select" className='minimal' style={{height: 40, marginBottom: 20}} onChange={e => this.setState({newOrganiserRole: e.target.value})}>
													<option value="admin" selected={this.state.newOrganiserRole === 'admin'}>Administrator</option>
													<option value="organisatie" selected={this.state.newOrganiserRole === 'organisatie'}>Organisatie</option>
												</Input>
											</Col>
										</Row>
										{this.state.newOrganiserRole === 'scanner' ?
										<Row>
											<Col sm={12}>
												<FormGroup>
													<Label htmlFor="example-email">Organisatie:</Label>
													<Input type="select" className='minimal' style={{width: '100%', marginBottom: 20}} onChange={e => {this.props.observableStore.setOrganisor(e.target.value); this.props.history.push("/")}}>
														<option value=''>Selecteer een organisatie</option>
														{this.props.observableStore.organisorList.map((evt, key) => {	
															return <option style={{cursor: 'pointer'}} value={key} selected={this.props.observableStore.userInfo.bedrijfsnaam === evt.bedrijfsnaam}>{evt.bedrijfsnaam}</option>
														})}
													</Input>
												</FormGroup>
											</Col>	
											<Col sm={12}>
												<FormGroup>
													<Label htmlFor="example-email">Evenement:</Label>
													<Input type="select" className='minimal' style={{width: '100%', marginBottom: 20}} onChange={e => {this.props.observableStore.setCurrEvent(e.target.value); this.props.history.push("/")}}>
														<option value=''>Selecteer een evenement</option>
														{this.props.observableStore.eventIndex.map((evt, key) => {	
															return <option style={{cursor: 'pointer'}} value={evt.eid} selected={this.props.observableStore.currEvent.ID === evt.eid}>{evt.naam}</option>
														})}
													</Input>
												</FormGroup>
											</Col>	
										</Row>
										:null}
									</ModalBody>
								:null}								
								{this.state.createUserStatus === 'process' ?
									<ModalBody>
										<div style={{padding: 25, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
											<div class="loading-spinner" style={{height: 50, width: 50}}></div>
											<div style={{fontSize: 14, width: '100%', textAlign: 'center', marginTop: 40}}>Account aanpassen/aanmaken...</div>
										</div>
									</ModalBody>
								:null}								
								{this.state.createUserStatus === 'succes' ?
									<ModalBody>
										<div style={{fontSize: 16, width: '100%', textAlign: 'center', padding: 20}}>Account {this.state.newPass === '' ? 'toegang aangepast' : 'aangemaakt. Het wachtwoord is: ' + this.state.newPass}</div>
									</ModalBody>
								:null}								
								{this.state.createUserStatus === 'failed' ?
									<ModalBody>
										<div style={{fontSize: 16, width: '100%', textAlign: 'center', padding: 20}}>Er is iets mis gegaan met het aanpassen/aanmaken van de account</div>
									</ModalBody>
								:null}								
								<ModalFooter>
									{this.state.createUserStatus === 'wait' ?
										<Button style={{backgroundColor: '#d12a5e', border: 0}} onClick={this.addOrganiser}>
										   {this.state.editAcc ? 'aanpassen' : 'toevoegen'}
										</Button>
									:null}
									<Button className="cancel_btn" onClick={this.toggleaccModal}>
									  Annuleren
									</Button>
								</ModalFooter>
							</Modal>
							
							<Modal isOpen={this.props.observableStore.newUser} className={this.props.className} centered >
								{this.state.newUserState === 1 ?
								<div>
									<ModalHeader>Welkom bij het partypay dashboard</ModalHeader>
									<ModalBody>
										<FormGroup>
											<Label htmlFor="example-email">Vul de naam in van jouw organisatie</Label>
											<Input type="text" id="example-email" name="name" placeholder="Bijv: Partypay Events" onChange={e => this.setState({newOrganisorName: e.target.value})}/>
										</FormGroup>
									</ModalBody>
									<ModalFooter>
										<Button style={{backgroundColor: '#d12a5e', border: 0}} onClick={this.saveNewUserInfo}>
										  Opslaan
										</Button>
									</ModalFooter>
								</div>
								:
								<div>
									<ModalHeader>Welkom bij het partypay dashboard</ModalHeader>
									<ModalBody>
										<p>Om dit dashboard te kunnen gebruiken moet je eerst een evenement of locatie aanmaken. Maak nu je eerste locatie of evenement aan.</p>
										<FormGroup>
											<Label htmlFor="example-email">Naam</Label>
											<Input type="text" id="example-email" name="name" placeholder="Bijv: Partypay festival 2020" onChange={e => this.setState({newEventName: e.target.value})}/>
										</FormGroup>
									</ModalBody>
									<ModalFooter>
										<Button style={{backgroundColor: '#d12a5e', border: 0}} onClick={this.saveNewUserInfo}>
										  Toevoegen
										</Button>
									</ModalFooter>
								</div>
								}
							</Modal>
							
							<Modal isOpen={this.state.publishModal} toggle={this.togglePublishModal} className={this.props.className} centered >
								<ModalHeader toggle={this.togglePublishModal}>Menu categorie toevoegen/updaten</ModalHeader>
								<ModalBody>
									<Row>
										<Col lg={6}>
											<label htmlFor="staticEmail" className="col-form-label">Cateraar:</label>
										</Col>
										<Col lg={6}>
											<label htmlFor="staticEmail" className="col-form-label">{this.props.observableStore.currEvent.notifications && this.props.observableStore.currEvent.notifications.length > 0 ? this.props.observableStore.currEvent.notifications.reverse()[this.state.publishInd].naam : null}</label>
										</Col>
									</Row>
									<Row>
										<Col lg={6}>
											<label htmlFor="staticEmail" className="col-form-label">E-mailadres:</label>
										</Col>
										<Col lg={6}>
											<label htmlFor="staticEmail" className="col-form-label">{this.props.observableStore.currEvent.notifications && this.props.observableStore.currEvent.notifications.length > 0 ? this.props.observableStore.currEvent.notifications.reverse()[this.state.publishInd].mail : null}</label>
										</Col>
									</Row>
									<Row>
										<Col lg={6}>
											<label htmlFor="staticEmail" className="col-form-label">Datum:</label>
										</Col>
										<Col lg={6}>
											<label htmlFor="staticEmail" className="col-form-label">{this.props.observableStore.currEvent.notifications && this.props.observableStore.currEvent.notifications.length > 0 ? this.formatDate(this.props.observableStore.currEvent.notifications.reverse()[this.state.publishInd].date) : null}</label>
										</Col>
									</Row>
									<Row>
										<Col lg={6}>
											<label htmlFor="staticEmail" className="col-form-label">Opmerking:</label>
										</Col>
										<Col lg={6}>
											<label htmlFor="staticEmail" className="col-form-label">{this.props.observableStore.currEvent.notifications && this.props.observableStore.currEvent.notifications.length > 0 ? this.props.observableStore.currEvent.notifications.reverse()[this.state.publishInd].opmerking : null}</label>
										</Col>
									</Row>									
									<div style={{width: '100%', backgroundColor: 'rgba(0,0,0,0.07)', height: 1, marginTop: 15, marginBottom: 15}}></div>
									<FormGroup>
										<label htmlFor="staticEmail" className="col-form-label">Selecteer menu</label>
										<Input type="select" className='minimal' style={{width: '100%', marginBottom: 20}} onChange={e => this.setState({currPublishMenu: e.target.value})}>
											{this.props.observableStore.currEvent.menus ? this.props.observableStore.currEvent.menus.map((menu, index) => (
												<option value={index}>{menu.label}</option>												
											)):null}
										</Input>
									</FormGroup>
									<FormGroup>
										<label htmlFor="staticEmail" className="col-form-label">Selecteer menu categorie</label>
										<Input type="select" className='minimal' style={{width: '100%', marginBottom: 20}} onChange={e => this.setState({currPublishCat: e.target.value})}>
											<option disabled selected>Selecteer een categorie</option>
											{this.props.observableStore.currEvent.menus ? this.props.observableStore.currEvent.menus[this.state.currPublishMenu].menu.map((cat, index) => (
												<option value={index}>{cat.name}</option>												
											)):null}
											<option disabled>-----------------------</option>
											<option value="new">Nieuwe categorie</option>
										</Input>
									</FormGroup>
									{this.state.currPublishCat === 'new' ?
										<FormGroup>
											<label htmlFor="staticEmail" className="col-form-label">Naam categorie</label>
											<InputGroup>
												<Input type="text" placeholder=""  value={this.state.newCatName} onChange={e => this.setState({newCatName: e.target.value})}/>
											</InputGroup>
										</FormGroup>
									:null}
								</ModalBody>
								<ModalFooter>
									{this.state.currPublishCat === 'new' ?
										<Button style={{backgroundColor: '#d12a5e', border: 0}} onClick={() => this.newMenuCat()}>
										  Aanmaken
										</Button>
									:
										<Button style={{backgroundColor: '#d12a5e', border: 0}} onClick={() => this.replaceMenuCat()}>
										  Aanpassen
										</Button>
									}
									<Button className="cancel_btn" onClick={this.togglePublishModal}>
									  Annuleren
									</Button>
								</ModalFooter>
							</Modal>
							
							<Modal isOpen={this.state.infoModal} toggle={() => this.toggleInfoModal('')} className={this.props.className} centered >
								<ModalHeader toggle={this.toggle}>Informatie</ModalHeader>
								<ModalBody>
									<p style={{marginTop: 10}}>{this.state.infoModalTxt}</p>
								</ModalBody>
								<ModalFooter>
									<Button style={{backgroundColor: '#d12a5e', border: 0}} onClick={() => this.toggleInfoModal('')}>
									  Sluit venster
									</Button>
								</ModalFooter>
							</Modal>
							<Modal isOpen={this.state.publishEventModal} toggle={this.togglePublishEventModal} className={this.props.className} centered >
								<ModalHeader toggle={this.togglePublishEventModal}>Publiceren naar app</ModalHeader>								
								<ModalBody>
									{this.props.observableStore.currEvent.publish || this.props.observableStore.isAdmin ?
									<div>
										{this.props.observableStore.publishStatus === '' ?
										<div>
											<div className="switchBtn">
												<div style={{position: 'relative'}}>
													<label htmlFor="staticEmail" className="col-form-label" style={{marginTop: 2}}>Instellingen</label>
													{this.props.observableStore.currEvent.publishNotf && this.props.observableStore.currEvent.publishNotf.settings ?
														<div style={{width: 6, height: 6, borderRadius: 3, backgroundColor: '#d12a5e', position: 'absolute', top: 11, right: -9}}></div>
													:null}
												</div>
												<div style={{cursor: 'pointer', marginTop: 3, height: 29, width: 55, padding: 3, backgroundColor: this.state.publishDetails ? '#d12a5e' : '#ccc', borderRadius: 25}} onClick={ () => {this.setState({publishDetails: !this.state.publishDetails})}}>
													<div style={{height: 23, width: 23, borderRadius: 12, backgroundColor: '#fff', float: this.state.publishDetails ? 'right' : 'left'}}></div>
												</div>
											</div>
											<div className="switchBtn">
												<div style={{position: 'relative'}}>
													<label htmlFor="staticEmail" className="col-form-label" style={{marginTop: 2}}>Lockers</label>
													{this.props.observableStore.currEvent.publishNotf && this.props.observableStore.currEvent.publishNotf.lockers  ?
														<div style={{width: 6, height: 6, borderRadius: 3, backgroundColor: '#d12a5e', position: 'absolute', top: 11, right: -9}}></div>
													:null}
												</div>
												<div style={{cursor: 'pointer', marginTop: 3, height: 29, width: 55, padding: 3, backgroundColor: this.state.publishLockers ? '#d12a5e' : '#ccc', borderRadius: 25}} onClick={ () => {this.setState({publishLockers: !this.state.publishLockers})}}>
													<div style={{height: 23, width: 23, borderRadius: 12, backgroundColor: '#fff', float: this.state.publishLockers ? 'right' : 'left'}}></div>
												</div>
											</div>
											<div className="switchBtn">
												<div style={{position: 'relative'}}>
													<label htmlFor="staticEmail" className="col-form-label" style={{marginTop: 2}}>Afhaalpunten</label>
													{this.props.observableStore.currEvent.publishNotf && this.props.observableStore.currEvent.publishNotf.afhaal ?
														<div style={{width: 6, height: 6, borderRadius: 3, backgroundColor: '#d12a5e', position: 'absolute', top: 11, right: -9}}></div>
													:null}
												</div>											<div style={{cursor: 'pointer', marginTop: 3, height: 29, width: 55, padding: 3, backgroundColor: this.state.publishBarren ? '#d12a5e' : '#ccc', borderRadius: 25}} onClick={ () => {this.setState({publishBarren: !this.state.publishBarren})}}>
													<div style={{height: 23, width: 23, borderRadius: 12, backgroundColor: '#fff', float: this.state.publishBarren ? 'right' : 'left'}}></div>
												</div>
											</div>
											<div className="switchBtn">
												<div style={{position: 'relative'}}>
													<label htmlFor="staticEmail" className="col-form-label" style={{marginTop: 2}}>Oplaadpunten</label>
													{this.props.observableStore.currEvent.publishNotf && this.props.observableStore.currEvent.publishNotf.oplaad ?
														<div style={{width: 6, height: 6, borderRadius: 3, backgroundColor: '#d12a5e', position: 'absolute', top: 11, right: -9}}></div>
													:null}
												</div>											<div style={{cursor: 'pointer', marginTop: 3, height: 29, width: 55, padding: 3, backgroundColor: this.state.publishOplaadpunten ? '#d12a5e' : '#ccc', borderRadius: 25}} onClick={ () => {this.setState({publishOplaadpunten: !this.state.publishOplaadpunten})}}>
													<div style={{height: 23, width: 23, borderRadius: 12, backgroundColor: '#fff', float: this.state.publishOplaadpunten ? 'right' : 'left'}}></div>
												</div>
											</div>
											<div className="switchBtn switchBtn2">
												<div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
													<div style={{position: 'relative'}}>
														<label htmlFor="staticEmail" className="col-form-label" style={{marginTop: 2}}>Assortiment</label>
														{this.props.observableStore.currEvent.publishNotf && this.props.observableStore.currEvent.publishNotf.menu ?
															<div style={{width: 6, height: 6, borderRadius: 3, backgroundColor: '#d12a5e', position: 'absolute', top: 11, right: -9}}></div>
														:null}
													</div>
													<div style={{cursor: 'pointer', marginTop: 3, height: 29, width: 55, padding: 3, backgroundColor: this.state.publishMenu ? '#d12a5e' : '#ccc', borderRadius: 25}} onClick={ () => {this.setState({publishMenu: !this.state.publishMenu})}}>
														<div style={{height: 23, width: 23, borderRadius: 12, backgroundColor: '#fff', float: this.state.publishMenu ? 'right' : 'left'}}></div>
													</div>
												</div>
												{this.state.publishMenu ?
												<Input type="select" className='minimal' style={{marginTop: 8, marginBottom: 8, width: '100%', height: 38}} onChange={e => {this.setState({publishMenuInd: e.target.value})}}>
													{this.props.observableStore.currEvent.menus ? this.props.observableStore.currEvent.menus.map((menu, index) => (	
														<option value={index} selected={this.state.publishMenuInd === index}>{menu.label.charAt(0).toUpperCase() + menu.label.slice(1)}</option>
													)):null}
													<option style={{color: '#d12a5e'}} value={'off'} selected={this.state.publishMenuInd === 'off'}>Tijdelijk niet beschikbaar</option>
												</Input>
												:null}
											</div>
										</div>
										:null}								
										{this.props.observableStore.publishStatus === 'await' ?						
											<center><p style={{marginTop: 10}}>Bezig met publiceren...</p></center>
										:null}
										{this.props.observableStore.publishStatus === 'finished' ?
											<center><p style={{marginTop: 10}}>Evenement is gepubliceerd!</p></center>
										:null}	
									</div>
									:
									<div><center><p style={{marginTop: 10}}>Bij de publicatie van een nieuw evenement moet het evenement eerst worden beoordeeld. Evenement zal bij goedkeuring direct worden gepubliceerd, alle aanpassingen die hierop volgen vereisen geen goedkeuring.</p></center></div>
									}
								</ModalBody>	
								{this.props.observableStore.currEvent.publish || this.props.observableStore.isAdmin ?					
								<ModalFooter style={{justifyContent: 'space-between'}}>
									<Button className="cancel_btn" onClick={() => this.publishAll()}>
										{this.state.publishAll ? 'Deselecteer alles' : 'Selecteer alles'}
									</Button>
									<div style={{display: 'flex'}}>
										{this.props.observableStore.publishStatus === '' ?
										<Button style={{backgroundColor: '#d12a5e', border: 0, marginRight: 10}} onClick={() => this.props.observableStore.publishEventInfo(this.state.publishDetails, this.state.publishMenu, this.state.publishBarren, this.state.publishOplaadpunten, this.state.publishLockers, this.state.publishMenuInd)}>
											Publiceren
										</Button>
										:null}
										<Button className="cancel_btn" onClick={this.togglePublishEventModal}>
											Sluit venster
										</Button>	
									</div>									
								</ModalFooter>
								:
								<ModalFooter style={{justifyContent: 'space-between'}}>
									<Button style={{backgroundColor: '#d12a5e', border: 0, marginRight: 10}} onClick={() => this.requestPublish()}>
										Verstuur aanvraag
									</Button>
									<Button className="cancel_btn" onClick={this.togglePublishEventModal}>
										Sluit venster
									</Button>									
								</ModalFooter>
								}
							</Modal>
							<Modal isOpen={this.state.incassoModal} toggle={this.toggleIncassoModal} className={this.props.className} style={{maxWidth: 400}} centered >
								<ModalHeader toggle={this.toggleIncassoModal}>Afschrijving mislukt</ModalHeader>
								<ModalBody>
									<Row>
										<Col lg={4}>
											<label htmlFor="staticEmail" className="col-form-label">Transactie:</label>
										</Col>
										<Col lg={8}>
											<label htmlFor="staticEmail" className="col-form-label">{this.state.incassoInfo.tid}</label>
										</Col>
									</Row>		
									<Row>
										<Col lg={4}>
											<label htmlFor="staticEmail" className="col-form-label">Kosten:</label>
										</Col>
										<Col lg={8}>
											<label htmlFor="staticEmail" className="col-form-label">€ {(parseInt(this.state.incassoInfo.kosten) / 100).toFixed(2)}</label>
										</Col>
									</Row>		
									<Row>
										<Col lg={4}>
											<label htmlFor="staticEmail" className="col-form-label">Gebruiker:</label>
										</Col>
										<Col lg={8}>
											<label htmlFor="staticEmail" className="col-form-label">{this.state.incassoInfo.uid}</label>
										</Col>
									</Row>		
									<Row>
										<Col lg={4}>
											<label htmlFor="staticEmail" className="col-form-label">Naam:</label>
										</Col>
										<Col lg={8}>
											<label htmlFor="staticEmail" className="col-form-label">{this.state.incassoInfo.naam}</label>
										</Col>
									</Row>		
									<Row>
										<Col lg={4}>
											<label htmlFor="staticEmail" className="col-form-label">Rekening:</label>
										</Col>
										<Col lg={8}>
											<label htmlFor="staticEmail" className="col-form-label">{this.state.incassoInfo.rekening}</label>
										</Col>
									</Row>		
									<Row>
										<Col lg={4}>
											<label htmlFor="staticEmail" className="col-form-label">Reden:</label>
										</Col>
										<Col lg={8}>
											<label htmlFor="staticEmail" className="col-form-label">{this.state.incassoInfo.reden}</label>
										</Col>
									</Row>						
								</ModalBody>
								<ModalFooter>
									<Button className="cancel_btn" onClick={this.toggleIncassoModal}>
									  Sluiten
									</Button>
								</ModalFooter>
							</Modal>
							<Modal isOpen={this.state.delModal} toggle={this.toggleDelModal} className={this.props.className} style={{maxWidth: 400}} centered >
								<ModalHeader toggle={this.toggleIncassoModal}>Evenement Verwijderen</ModalHeader>
								<ModalBody>
									<center><p>Weet je zeker dat je {this.state.selectedEventName ? this.state.selectedEventName : 'dit evenement'} wilt verwijderen? Dit is onomkeerbaar!</p></center>
								</ModalBody>
								<ModalFooter>
								<Button style={{backgroundColor: '#d12a5e', border: 0, marginRight: 10}} onClick={() => this.delEvent()}>
										Verwijder evenement
									</Button>
									<Button className="cancel_btn" onClick={this.toggleDelModal}>
									  	Sluiten
									</Button>
								</ModalFooter>
							</Modal>
						</div>
					</div>
					<div className="page-content container-fluid">
						<Switch>
							{ThemeRoutes.map((prop, key) => {
								if (prop.navlabel) {
									return null;
								}
								else if (prop.collapse) {
									return prop.child.map((prop2, key2) => {
										if (prop2.collapse) {
											return prop2.subchild.map((prop3, key3) => {
												return (
													<Route path={prop3.path} component={prop3.component} key={key3} />
												);
											});
										}
										return (
											<Route path={prop2.path} component={prop2.component} key={key2} />
										);
									});
								}
								else if (prop.redirect) {
									return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
								}
								else {
									return (
										<Route path={prop.path} component={prop.component} key={key} />
									);
								}
							})}
						</Switch>
					</div>
				</div>
			</div>
		);
	}
}
export default withRouter(Fulllayout);
