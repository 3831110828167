import React from "react";
import {Row, Col, Label, Table, Button, ModalFooter, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Modal, ModalHeader, ModalBody} from 'reactstrap';
import { observer, inject } from 'mobx-react';
import { db, functions, functionsEU, fieldvalue } from '../components/firebase/firebase';
import Warning from '../assets/images/pp_warning.svg';
import Return from '../assets/images/pp_return.svg';


@inject('observableStore')
@observer

class Barren extends React.Component {
render() {
	return (
		<div style={{paddingLeft: 10, paddingRight: 10, paddingBottom: 100}}>
			<Row style={{paddingLeft: 15, paddingRight: 15}}>
				<h2 className="font-light text-muted topTitle" style={{fontSize: 28, fontWeight: 900, color: '#212529'}}>Bezoekers</h2>
				<div style={{marginTop: 10, width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-end'}}>
					<div style={{position: 'relative', background: 'none', width: 350, paddingTop: 0, paddingRight: 0, paddingLeft: 0, minWidth: 240}}>
						{this.state.uidValid === 1 ?
							<div style={{overflow: 'hidden', width: '100%'}}>
								<div className="addBtnLst" style={{position: 'absolute', right: 0, cursor: 'pointer', marginBottom: 0, width: 50, background: '#d12a5e', color: '#fff', border: 0, borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: 7, borderBottomRightRadius: 7,}} onClick={() => this.clearUser()}><i class={'fa fa-times'} style={{fontSize: 14, color: '#fff'}}></i></div>
								<div style={{width: '95%', paddingLeft: 15, paddingRight: 80, overflow: 'hidden', height: 38, backgroundColor: 'rgba(0,0,0,0.05)', display: 'flex', alignItems: 'center', borderTopLeftRadius: 7, borderBottomLeftRadius: 7, borderTopRightRadius: 0, borderBottomRightRadius: 0, }}>{this.state.uid}</div>
							</div>
						:
							<div style={{overflow: 'hidden', width: '100%'}}>
								{this.state.waitMail ?
								<div style={{width: '100%', height: 38, display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute'}}>
									<div class="loading-spinner" style={{borderWidth: 3, height: 20, width: 20, marginRight: 65}}></div>
								</div>
								:null}
								<div className="addBtnLst" style={{position: 'absolute', right: 0, cursor: 'pointer', marginBottom: 0, width: 65, background: '#d12a5e', color: '#fff', border: 0, borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: 7, borderBottomRightRadius: 7,}} onClick={() => this.checkUser()}><i class={'fa fa-search'} style={{fontSize: 14, color: '#fff'}}></i></div>
								<input onKeyDown={(e) => {if(e.key === 'Enter'){this.checkUser()}}} type="text" className="form-control" placeholder="UID, E-mail of Card URL" style={{paddingLeft: 15, borderWidth: 1, borderStyle: 'solid', borderTopLeftRadius: 7, borderBottomLeftRadius: 7, borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRightWidth: 0, borderColor: 'rgba(0,0,0,0.2)', width: '95%', height: 38, backgroundColor: this.state.uidValid === 2 ? '#ff9090' : ''}} value={this.state.uid} onChange={e => this.setState({uid: e.target.value, uidValid: 0})} />
							</div>
						}					
					</div>
				</div>
			</Row>	
			<Row className="menuTopCats">
				<Col lg={2} className="graph-display" style={{background: 'none', paddingTop: 0, paddingRight: 0, paddingLeft: 0, minWidth: 240}}>
					<div className="settingsnav" style={{backgroundColor: this.state.bezoekerCat === 'info' ? '#f6f6f6' : '', borderLeftWidth: this.state.bezoekerCat === 'info' ? 6 : 0}} onClick={() => this.setState({bezoekerCat: 'info'})}>
						<span>Bezoeker info</span>
					</div>
					<div className="settingsnav" style={{backgroundColor: this.state.bezoekerCat === 'transacties' ? '#f6f6f6' : '', borderLeftWidth: this.state.bezoekerCat === 'transacties' ? 6 : 0}} onClick={() => this.setState({bezoekerCat: 'transacties'})}>
						<span>Transacties</span>
					</div>
					<div className="settingsnav" style={{backgroundColor: this.state.bezoekerCat === 'kluisjes' ? '#f6f6f6' : '', borderLeftWidth: this.state.bezoekerCat === 'kluisjes' ? 6 : 0}} onClick={() => this.setState({bezoekerCat: 'kluisjes'})}>
						<span>Kluisjes</span>
					</div>
					<div className="settingsnav" style={{backgroundColor: this.state.bezoekerCat === 'tickets' ? '#f6f6f6' : '', borderLeftWidth: this.state.bezoekerCat === 'tickets' ? 6 : 0}} onClick={() => this.setState({bezoekerCat: 'tickets'})}>
						<span>Tickets</span>
					</div>
				</Col>
				<Col className="graph-display graph-menu" style={{marginLeft: 20, padding: 40, paddingTop: 30}}>	
					
					{this.state.bezoekerCat === 'info' ?	
					<div style={{minHeight: 450}}>
						<div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 5}}>
							<h2 className="font-light text-muted" style={{marginTop: 10, marginRight: 20, fontSize: 20, fontWeight: 900, color: '#212529',}}>Bezoeker info</h2>
						</div>
						<div style={{marginTop: 35}}>
							<Form className="mt-1">
								{this.state.userID !== '' ?
								<Row>
									<Col lg={6}>
										<FormGroup style={{marginBottom: 20}}>
											<InputGroup>
												<InputGroupAddon addonType="prepend" style={{height: 44, backgroundColor: '#f2f2f2'}}>
													<InputGroupText style={{background: '#fff', width: 140, borderTopLeftRadius: 10, borderBottomLeftRadius: 10}}>Naam</InputGroupText>
												</InputGroupAddon>
												<Input type="text" style={{height: 44, borderTopRightRadius: 10, borderBottomRightRadius: 10}} value={this.state.name}/>
											</InputGroup>
										</FormGroup>								
										<FormGroup style={{marginBottom: 20}}>
											<InputGroup>
												<InputGroupAddon addonType="prepend" style={{height: 44, backgroundColor: '#f2f2f2'}}>
													<InputGroupText style={{background: '#fff', width: 140, borderTopLeftRadius: 10, borderBottomLeftRadius: 10}}>UserID</InputGroupText>
												</InputGroupAddon>
												<Input type="text" style={{height: 44, borderTopRightRadius: 10, borderBottomRightRadius: 10}} value={this.state.userID} onChange={e => this.setState({userID: this.state.userID})}/>
											</InputGroup>
										</FormGroup>
										<FormGroup style={{marginBottom: 20}}>
											<InputGroup>
												<InputGroupAddon addonType="prepend" style={{height: 44, backgroundColor: '#f2f2f2'}}>
													<InputGroupText style={{background: '#fff', width: 140, borderTopLeftRadius: 10, borderBottomLeftRadius: 10}}>IBAN</InputGroupText>
												</InputGroupAddon>
												<Input type="text" style={{height: 44, borderTopRightRadius: 10, borderBottomRightRadius: 10}} value={this.state.IBAN}/>
											</InputGroup>
										</FormGroup>
										{this.props.observableStore.currEvent.idin ?
										<Row onClick={() => this.setState({isEighteen: !this.state.isEighteen})} style={{cursor: 'pointer', marginTop: 25, marginBottom: 7, marginLeft: 1}}>
											{this.state.isEighteen ?
											<div style={{width: 20, height: 20, borderWidth: 2, borderStyle: 'solid', borderColor: '#4cc15f', borderRadius: 4, marginRight: 10}}>
												<i class="fa fa-check" style={{color: '#4cc15f', paddingLeft: 1, marginTop: 2, position: 'absolute'}}></i>
											</div>
											:
											<div style={{width: 20, height: 20, borderWidth: 2, borderStyle: 'solid', borderColor: 'rgba(0,0,0,0.2)', borderRadius: 4, marginRight: 10}}></div>
											}
											<p style={{fontSize: 13, marginTop: 2, color: '#4a4a4a'}}>Leeftijd geverifieerd en ouder dan 18.</p>
										</Row>
										:null}
									</Col>
									<Col lg={6}>
										<FormGroup style={{marginBottom: 20}}>
											<InputGroup>
												<InputGroupAddon addonType="prepend" style={{height: 44, backgroundColor: '#f2f2f2'}}>
													<InputGroupText style={{background: '#fff', width: 140, borderTopLeftRadius: 10, borderBottomLeftRadius: 10}}>Tokens</InputGroupText>
												</InputGroupAddon>
												<Input type="number" style={{height: 44, borderTopRightRadius: 10, borderBottomRightRadius: 10}} value={this.state.saldo} onChange={e => this.setState({saldo: e.target.value})}/>
											</InputGroup>
										</FormGroup>
										<FormGroup style={{marginBottom: 20}}>
											<InputGroup>
												<InputGroupAddon addonType="prepend" style={{height: 44, backgroundColor: '#f2f2f2'}}>
													<InputGroupText style={{background: '#fff', width: 140, borderTopLeftRadius: 10, borderBottomLeftRadius: 10}}>Promotie tokens</InputGroupText>
												</InputGroupAddon>
												<Input type="number" style={{height: 44, borderTopRightRadius: 10, borderBottomRightRadius: 10}} value={this.state.saldoPromo} onChange={e => this.setState({saldoPromo: e.target.value})}/>
											</InputGroup>
										</FormGroup>
										<div className="addBtnLst" style={{cursor: 'pointer', width: 150, background: '#d12a5e', color: '#fff'}} onClick={() => this.saveUserInfo()}>Opslaan</div>
									</Col>
								</Row>
								:
								<Row>
									<div className="emptyContainer" style={{borderWidth: 0, minHeight: 300, flexDirection: 'column', justifyContent: 'center'}}>
										<img src={Warning} style={{width: 50, marginBottom: 15}} />
										<span>Er is nog geen bezoeker geselecteerd</span>
									</div>
								</Row>
								}
							</Form>
						</div>
					</div>
					:null}

					{this.state.bezoekerCat === 'transacties' ?	
					<div style={{minHeight: 450}}>						
						<div style={{display: 'flex'}}>
							<h2 className="font-light text-muted" style={{marginTop: 10, paddingBottom: 12, fontSize: 20, fontWeight: 900, color: '#212529',}}>Transacties</h2>	
							<h2 className="font-light text-muted" style={{marginTop: 10, marginLeft: 8, paddingBottom: 12, fontSize: 12, fontWeight: 200, color: '#212529',}}>(Laatste 10)</h2>	
						</div>
						{this.state.loaded ?
						<Table className="v-middle" responsive style={{marginBottom: 0, borderWidth: 0}}>
							<thead>
								<tr>
									<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>Transactie ID</td>
									<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>Type</td>
									<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>Datum/tijd</td>
									<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>Bedrag</td>
									<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>Muntjes</td>
									<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}></td>
									<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>Status</td>
								</tr>
							</thead>
							<tbody>
							{this.state.transacties.length > 0 ? this.state.transacties.map((tr, index) => (								
								<tr style={{background: index%2 === 0 ? null : 'rgba(0,0,0,0.03)'}}>
									<td>
										{tr.type === 'order' ?
										<table>
											<tr><td style={{padding: 0, paddingTop: 10, verticalAlign: 'top', paddingBottom: 15}}><h6 style={{fontSize: 14, fontWeight: 500}}>{tr.id}</h6></td></tr>
											{tr.data.items ? tr.data.items.map(item=> (
											<tr><td style={{padding: 0, fontSize: 12, verticalAlign: 'top'}}><p style={{margin: 2, opacity: 0.7}}>{item.aantal} {item.naam}</p></td></tr>
											)):null}
										</table>
										:<h6 style={{fontSize: 14, fontWeight: 500}}>{tr.id}</h6>}
									</td>
									<td style={{padding: 20, paddingTop: 25, verticalAlign: 'top'}}><h6 style={{fontSize: 14, fontWeight: 500}}>{tr.type}{tr.id.slice(0, 2) === 'IO' ? ' (incasso)' : ''}</h6></td>
									<td style={{padding: 20, paddingTop: 25, verticalAlign: 'top'}}><h6 style={{fontSize: 14, fontWeight: 500}}>{this.formatTime(tr.data.tijd)}</h6></td>
									<td style={{padding: 20, paddingTop: 25, verticalAlign: 'top'}}><h6 style={{fontSize: 14, fontWeight: 500}}>{this.formatCurrency(tr.data.bestelprijs)}</h6></td>										
									<td style={{padding: 20, paddingTop: 25, verticalAlign: 'top'}}>
										{this.isPositiv(tr) === 'green' ?
										<h6 style={{fontSize: 14, fontWeight: 500, color: '#4cc15f'}}>+{this.tokenAmount(tr)} {this.tokenAmount(tr) === 1 ? 'muntje' : 'muntjes'}</h6>
										:null}
										{this.isPositiv(tr) === 'red' ?
										<h6 style={{fontSize: 14, fontWeight: 500, color: '#b32e5d'}}>{this.tokenAmount(tr) < 0 ? '' : '-'}{this.tokenAmount(tr)} {this.tokenAmount(tr) === 1 ? 'muntje' : 'muntjes'}</h6>
										:null}
										{this.isPositiv(tr) === 'grey' ?
										<h6 style={{fontSize: 14, fontWeight: 500, color: 'rgba(0,0,0,0.2)'}}>{this.tokenAmount(tr)} {this.tokenAmount(tr) === 1 ? 'muntje' : 'muntjes'}</h6>
										:null}
										{tr.data && tr.data.orderData && tr.data.orderData.locker && tr.data.orderData.locker.aantal > 0 ?
										<h6 style={{fontSize: 14, fontWeight: 500}}>+ 1 kluisje</h6>
										:null}
										</td>
									<td style={{padding: 20, paddingTop: 25, verticalAlign: 'top'}}>{tr.refunded ? <h6 style={{fontSize: 14, fontWeight: 500}}>Geretourneerd</h6> : null}{!tr.data.refunded && tr.data.status === 'PAID' && tr.data.orderData.tokens.aantal > 0 ? <img src={Return} style={{width: 20, cursor: 'pointer'}} onClick={() => this.toggleRetourneerModal(index)} /> : null}</td>
									<td style={{width: '20%', padding: 20, fontSize: 18, padding: 0, paddingTop: 20, paddingBottom: 20, textAlign: 'right', verticalAlign: 'top'}}><h6 style={{fontWeight: 500, background: this.statusColor(tr.data.status), width: '70%', minWidth: 180, textAlign: 'center', borderRadius: 5, color: '#fff', padding: 6, paddingTop: 7}}>{tr.data.status}</h6></td>													
								</tr>
							))
							:
								<div className="emptyContainer" style={{position: 'absolute', paddingTop: 30, flexDirection: 'column', justifyContent: 'center'}}>
									<img src={Warning} style={{width: 50, marginBottom: 15}} />
									<span>{this.state.uidValid === 1 ?  'Er zijn nog geen transacties.' : 'Er is nog geen bezoeker geselecteerd'}</span>
								</div>
							}
							</tbody>
						</Table>
						:
						<div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingTop: 100}}>
							<div class="loading-spinner" style={{width: 45, height: 45, borderWidth: 4}}></div>
							<p style={{marginTop: 30}}>Transacties ophalen...</p>
						</div>
						}
					</div>
					:null}

					{this.state.bezoekerCat === 'kluisjes' ?	
					<div style={{minHeight: 450}}>						
						<div style={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
							<div style={{display: 'flex'}}>
								<h2 className="font-light text-muted" style={{marginTop: 10, paddingBottom: 12, fontSize: 20, fontWeight: 900, color: '#212529',}}>Kluisjes</h2>	
								<h2 className="font-light text-muted" style={{marginTop: 10, marginLeft: 8, paddingBottom: 12, fontSize: 12, fontWeight: 200, color: '#212529',}}>(Laatste 5)</h2>									
							</div>
							<div className="addBtnLst" style={{cursor: 'pointer', width: 150, background: '#d12a5e', color: '#fff'}} onClick={() => this.toggleLockerModal()}>Kluisje toekennen</div>
						</div>							
						{this.state.loaded ?
						<Table className="v-middle" responsive style={{marginBottom: 0, borderWidth: 0}}>
							<thead>
								<tr>
									<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>Datum/tijd</td>
									<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>ID</td>
									<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>Nummer</td>
									<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>Pincode</td>
									<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>Info</td>
								</tr>
							</thead>
							<tbody>
							{this.state.lockers && this.state.lockers.length > 0 ? this.state.lockers.map((lo, index) => (								
								<tr style={{background: index%2 === 0 ? null : 'rgba(0,0,0,0.03)'}}>
									<td style={{padding: 20}}><h6 style={{fontSize: 14, fontWeight: 500}}>{this.formatTime(parseFloat(lo.geldig))}</h6></td>
									<td style={{padding: 20}}><h6 style={{fontSize: 14, fontWeight: 500}}>{lo.id}</h6></td>
									<td style={{padding: 20}}><h6 style={{fontSize: 14, fontWeight: 500}}>{lo.num ? ('Nummer: ' + lo.num) : 'Nog geen nummer'}</h6></td>
									<td style={{padding: 20}}><h6 style={{fontSize: 14, fontWeight: 500}}>{lo.code ? ('Pincode: ' + lo.code) : 'Nog geen pincode'}</h6></td>
									<td style={{padding: 20}}><h6 style={{fontSize: 14, fontWeight: 500}}>{lo.desc}</h6></td>
								</tr>
							))
							:
								<div className="emptyContainer" style={{position: 'absolute', paddingTop: 30, flexDirection: 'column', justifyContent: 'center'}}>
									<img src={Warning} style={{width: 50, marginBottom: 15}} />
									<span>{this.state.uidValid === 1 ?  'Er zijn nog geen lockers.' : 'Er is nog geen bezoeker geselecteerd'}</span>
								</div>
							}
							</tbody>
						</Table>
						:
						<div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingTop: 100}}>
							<div class="loading-spinner" style={{width: 45, height: 45, borderWidth: 4}}></div>
							<p style={{marginTop: 30}}>Transacties ophalen...</p>
						</div>
						}
					</div>
					:null}
	

					{this.state.bezoekerCat === 'tickets' ?	
					<div style={{minHeight: 450}}>						
						<div style={{display: 'flex'}}>
							<h2 className="font-light text-muted" style={{marginTop: 10, paddingBottom: 12, fontSize: 20, fontWeight: 900, color: '#212529',}}>Tickets</h2>	
							<h2 className="font-light text-muted" style={{marginTop: 10, marginLeft: 8, paddingBottom: 12, fontSize: 12, fontWeight: 200, color: '#212529',}}>(Laatste 5)</h2>	
						</div>							
						{this.state.loaded ?
						<Table className="v-middle" responsive style={{marginBottom: 0, borderWidth: 0}}>
							<thead>
								<tr>
									<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>Naam</td>
									<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>Nummer</td>
									<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>Geldig vanaf</td>
									<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>Geldig t/m</td>
									<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>Download link</td>
								</tr>
							</thead>
							<tbody>
							{this.state.tickets && this.state.tickets.length > 0 ? this.state.tickets.map((ti, index) => (								
								<tr style={{background: index%2 === 0 ? null : 'rgba(0,0,0,0.03)'}}>
									<td style={{padding: 20}}><h6 style={{fontSize: 14, fontWeight: 500}}>{ti.name}</h6></td>
									<td style={{padding: 20}}><h6 style={{fontSize: 14, fontWeight: 500}}>{ti.qrvalue}</h6></td>
									<td style={{padding: 20}}><h6 style={{fontSize: 14, fontWeight: 500}}>{this.formatTime(parseFloat(ti.vanaf))}</h6></td>
									<td style={{padding: 20}}><h6 style={{fontSize: 14, fontWeight: 500}}>{this.formatTime(parseFloat(ti.tm))}</h6></td>
									<td style={{padding: 20}}><h6 style={{fontSize: 14, fontWeight: 500, maxWidth: 200}}>{ti.download}</h6></td>
								</tr>
							))
							:
								<div className="emptyContainer" style={{position: 'absolute', paddingTop: 30, flexDirection: 'column', justifyContent: 'center'}}>
									<img src={Warning} style={{width: 50, marginBottom: 15}} />
									<span>{this.state.uidValid === 1 ?  'Er zijn nog geen tickets.' : 'Er is nog geen bezoeker geselecteerd'}</span>
								</div>
							}
							</tbody>
						</Table>
						:
						<div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingTop: 100}}>
							<div class="loading-spinner" style={{width: 45, height: 45, borderWidth: 4}}></div>
							<p style={{marginTop: 30}}>Transacties ophalen...</p>
						</div>
						}
					</div>
					:null}


				</Col>
			</Row>
			<Modal isOpen={this.state.saveModal} toggle={this.toggleSaveModal} className={this.props.className} centered >
				<ModalHeader toggle={this.toggle}>Wijzigingen opslaan</ModalHeader>
				<ModalBody>
					<p style={{marginTop: 10}}>Saldo is gewijzigd.</p>
				</ModalBody>
				<ModalFooter>
					<Button style={{backgroundColor: '#d12a5e', border: 0}} onClick={this.toggleSaveModal}>
					  Sluit venster
					</Button>
				</ModalFooter>
			</Modal>
			<Modal isOpen={this.state.infoModal} toggle={() => this.toggleInfoModal('')} className={this.props.className} centered >
				<ModalHeader toggle={this.toggle}>Foutmelding</ModalHeader>
				<ModalBody>
					<p style={{marginTop: 10}}>{this.state.infoModalTxt}</p>
				</ModalBody>
				<ModalFooter>
					<Button style={{backgroundColor: '#d12a5e', border: 0}} onClick={() => this.toggleInfoModal('')}>
					  Sluit venster
					</Button>
				</ModalFooter>
			</Modal>
			<Modal isOpen={this.state.retourneerModal} toggle={() => this.toggleRetourneerModal()} className={this.props.className} centered >
				<ModalHeader toggle={() => this.toggleRetourneerModal()}>Muntjes retourneren</ModalHeader>
				<ModalBody>
					{this.state.retourneerState === 0 ?
					<div>
						<FormGroup>
							<label htmlFor="staticEmail" className="col-form-label">Hoeveel muntjes wil je retourneren?</label>
							<InputGroup>
								<Input type="number" onChange={e => e.target.value <= this.setState({retourneerTokens: e.target.value})}/>
							</InputGroup>
						</FormGroup>	
					</div>
					:null}
					{this.state.retourneerState === 1 ?
						<div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingTop: 40, paddingBottom: 40}}>
							<div class="loading-spinner" style={{width: 35, height: 35, borderWidth: 4}}></div>
							<p style={{marginTop: 20}}>Transactie retourneren</p>
						</div>
					:null}
					{this.state.retourneerState === 2 ?
						<p style={{marginTop: 10}}>Muntjes zijn geretourneerd.</p>
					:null}	
					{this.state.retourneerState === 3 ?
						<p style={{marginTop: 10}}>Er is iets mis gegaan.</p>
					:null}	
				</ModalBody>
				<ModalFooter>
					{this.state.retourneerState === 0 ?
					<Button style={{backgroundColor: '#d12a5e', border: 0, marginLeft: 10}} onClick={() => this.returnTransaction()}>
						Retourneer
					</Button>
					:null}
					<Button className="cancel_btn" style={{marginLeft: 10}} onClick={() => this.toggleRetourneerModal()}>
					  Sluit venster
					</Button>
				</ModalFooter>
			</Modal>
			<Modal isOpen={this.state.lockerModal} toggle={() => this.toggleLockerModal()} className={this.props.className} centered >
				<ModalHeader toggle={() => this.toggleLockerModal()}>Kluisje toekennen</ModalHeader>
				<ModalBody>
					<p style={{marginTop: 10}}>Je kunt hieronder een kluisje toekennen aan de bezoeker. Deze komt direct in het account te staan.</p>
					<FormGroup>
						<label htmlFor="staticEmail" className="col-form-label" style={{marginBottom: 2}}>Selecteer een periode</label>
						<Input type="select" className='minimal' style={{width: '100%', height: 40, paddingBottom: 10, paddingTop: 10, paddingRight: 10, borderColor: 'rgb(217, 217, 217)', borderRadius: 12}} onChange={e => {this.setState({addLockerPeriod: e.target.value})}}>
							{this.getLockerDates().map((date, ind) => (<option value={ind} selected={this.state.addLockerPeriod === ind}>{date.desc} ({this.formatDate(date.start)} / {this.formatDate(date.end)})</option>))}
						</Input>									
					</FormGroup>
					<FormGroup>
						<label htmlFor="staticEmail" className="col-form-label" style={{marginBottom: 2}}>Selecteer een locker</label>
						<Input type="select" className='minimal' style={{width: '100%', height: 40, paddingBottom: 10, paddingTop: 10, paddingRight: 10, borderColor: 'rgb(217, 217, 217)', borderRadius: 12}} onChange={e => {this.setState({addLockerInd: e.target.value})}}>
							{(this.getLockerDates()[this.state.addLockerPeriod] && this.getLockerDates()[this.state.addLockerPeriod].lockers ? this.getLockerDates()[this.state.addLockerPeriod].lockers : []).map((lock, ind) => (<option value={ind} selected={this.state.addLockerInd === ind}>{lock.desc}</option>))}
						</Input>									
					</FormGroup>
				</ModalBody>
				<ModalFooter>
					<Button style={{backgroundColor: '#d12a5e', border: 0, marginLeft: 10}} onClick={() => this.addLocker()}>
						Toekennen
					</Button>
					<Button className="cancel_btn" style={{marginLeft: 10}} onClick={() => this.toggleLockerModal()}>
					  	Sluit venster
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
}
	
state = {
	bezoekerCat: 'info',
	saveModal: false,
	waitMail: false,
	infoModal: false,
	infoModalTxt: '',
	uid: '',
	uidValid: 0,
	IBAN: '', 
	isEighteen: false,
	name: '',
	userID: '', 
	saldo: 0,
	saldoPromo: 0,
	transacties: [],
	bestellingen: [],
	promoties: [],
	lockers: [],
	loaded: true,
	retourneerModal: false,
	retourneerState: 0,
	retourneerTokens: 0,
	retourneerTransInd: 0,

	addLockerPeriod: 0,
	addLockerInd: 0
}	

toggleSaveModal = () => this.setState({saveModal: !this.state.saveModal});
toggleInfoModal = (error) => this.setState({infoModal: !this.state.infoModal, 'infoModalTxt': error});
toggleRetourneerModal = (ind) => this.setState({retourneerModal: !this.state.retourneerModal, retourneerState: 0, retourneerTokens: 0, retourneerTransInd: ind ? ind : 0});
toggleLockerModal = () => this.setState({lockerModal: !this.state.lockerModal, addLockerPeriod: 0, addLockerInd: 0});

checkUser() {	
	if(this.state.uid !== ''){
		if(this.re.test(String(this.state.uid).toLowerCase()) || this.state.uid.includes('card.partypay.nl')){
			this.setState({waitMail: true});
			var getUID = functions.httpsCallable('getUID');
			getUID({user: this.state.uid, eid: this.props.observableStore.currEvent.ID}).then(result => this.getUserInfo(result.data.uid)).catch(err => this.setState({uidValid: 2, waitMail: false}));
		}
		else{
			this.getUserInfo(this.state.uid);
		}
	}
}

getUserInfo(uid){
	const dbref = db.collection("events").doc(this.props.observableStore.currEvent.ID).collection("users").doc(uid);
	dbref.get().then(doc => {		
		if(doc.exists) {
			this.setState({
				waitMail: false, 
				saldo: doc.data().tokens ? doc.data().tokens : 0, 
				userID: uid, 
				IBAN: doc.data().paymentInfo ? doc.data().paymentInfo.account : 'Nog niet bekend', 
				name: doc.data().paymentInfo ? doc.data().paymentInfo.name : 'Nog niet bekend', 
				saldoPromo: doc.data().promotokens ? doc.data().promotokens : 0, 
				isEighteen: doc.data().isEighteen ? true : false,
				uidValid: 1
			},()=>{this.loadCollection()});
		}
		else{dbref.set({tokens: 0}).then(()=>this.getUserInfo(uid))}
	}).catch(err => {console.log(err); this.setState({waitMail: false, uidValid: 2})});
}

loadCollection(){
	if(this.state.userID !== ''){
		this.setState({loaded: false});
		const dbref = db.collection("events").doc(this.props.observableStore.currEvent.ID).collection("users").doc(this.state.userID);
		dbref.collection('orders').orderBy("tijd", "desc").limit(50).get().then(querySnapshot => {
			var transacties = [];
			querySnapshot.forEach(doc => {if(doc.data().type){transacties.push({id: doc.id, type: doc.data().type, data: doc.data()})}});
			this.setState({transacties: transacties, loaded: true});
		})
		dbref.collection('lockers').orderBy("start", "desc").limit(5).get().then(querySnapshot => {
			var lockerList = [];
			querySnapshot.forEach(doc => {lockerList.push({id: doc.id, desc: doc.data().desc, fetched: doc.data().fetched, num: doc.data().num, code: doc.data().code, geldig: doc.data().start})});
			this.setState({lockers: lockerList, loaded: true});
		})
		dbref.collection('tickets').orderBy("vanaf", "desc").limit(5).get().then(querySnapshot => {
			var ticketList = [];
			querySnapshot.forEach(doc => {ticketList.push(doc.data())});
			this.setState({tickets: ticketList, loaded: true});
		})
	}
}

saveUserInfo(){
	var updateObj = {tokens: parseFloat(this.state.saldo), promotokens: parseFloat(this.state.saldoPromo)};
	if(this.props.observableStore.currEvent.idin){updateObj['isEighteen'] = this.state.isEighteen}
	db.collection("events").doc(this.props.observableStore.currEvent.ID).collection("users").doc(this.state.userID).update(updateObj);
	this.toggleSaveModal();
}

formatTime(tijd, notime){
	const monthNames = ["jan", "feb", "mar", "apr", "mei", "jun","jul", "aug", "sep", "okt", "nov", "dec"];
	var datum = new Date(0);
	datum.setUTCSeconds(tijd);
	var minuten = datum.getMinutes();
	if(datum.getMinutes() < 10){minuten = '0' + datum.getMinutes()}
	var timestamp = ("0" + datum.getDate()).slice(-2) + ' ' + monthNames[datum.getMonth()] + (!notime ? ' - ' + datum.getHours() + ':' + minuten : '');
	return timestamp;
}

formatCurrency = (value) => {
	if(value) return '€ ' + value.toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2});
}

clearUser(){
	this.setState({bezoekerCat: 'info', uid: '', uidValid: 0, saldo: 0, saldoPromo: 0, IBAN: '', isEighteen: false, name: '', userID: '', transacties: [], bestellingen: [], promoties: [], lockers: [], tickets: []});
}

re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

returnTransaction(){
	this.setState({retourneerState: 1});
	var retourVerzoek = functionsEU.httpsCallable('refundTransaction');
	retourVerzoek({tokens: this.state.retourneerTokens, uid: this.state.userID, transaction: this.state.transacties[this.state.retourneerTransInd].id, eid: this.props.observableStore.currEvent.ID, oid: this.props.observableStore.currEvent.oid}).then(result => {
		if(result.data.text === 'verwerkt'){
			this.setState({retourneerState: 2});
			setTimeout(() => {this.loadCollection(); this.toggleRetourneerModal()}, 3000);
		}
		else{
			this.setState({retourneerState: 3});
			setTimeout(() => {this.setState({retourneerState: 0, retourneerTokens: 0})}, 3000);
		}
	}).catch(err => this.setState({retourneerState: 3}));
}

tokenAmount(item){
	if(item.type === 'order'){return parseFloat(item.data.kosten)}
	return item.data.orderData['tokens'].aantal;
}

isPositiv(item){
	if(item.type === 'onlinetrans'){
		if(item.data.status === 'PAID' && item.data.orderData['tokens'].aantal > 0){return 'green'}
		if(item.data.status === 'Geincasseerd' && item.data.orderData['tokens'].aantal > 0){return 'green'}
		return 'grey';
	}
	if(item.type === 'offlinetrans'){
		if(item.data.status === 'paidoffline'){return 'green'}
		return 'grey';
	}
	if(item.type === 'sharetrans' && item.data.status === 'sharerecieved'){return 'green'}
	if(item.type === 'transpromotion'){return 'green'}
	if(item.type === 'headphone' && item.data.status === 'headphonesReturned'){return 'green'}
	return 'red';
}

statusColor(status){
	if(status === 'PAID'){return '#43A047'}
	if(status === 'Geincasseerd'){return '#43A047'}
	if(status === 'open'){return '#e53935'}
	if(status === 'REFUNDING'){return '#e53935'}
	if(status === 'paidoffline'){return '#43A047'}
	if(status === 'failed'){return '#e53935'}
	if(status === 'Terugboeking'){return '#e53935'}
	if(status === 'sharerecieved'){return '#43A047'}
	if(status === 'sharesend'){return '#43A047'}
	if(status === 'promo'){return '#43A047'}
	if(status === 'PENDING'){return '#e53935'}
	if(status === 'Verwerkt'){return '#e53935'}
	if(status === 'Toegevoegd'){return '#e53935'}
	if(status === 'In batch'){return '#e53935'}
	if(status === 'REFUND'){return '#43A047'}
	if(status === 'PARTIAL_REFUND'){return '#43A047'}
	if(status === 'EXPIRED'){return '#e53935'}
	if(status === 'FAILURE'){return '#e53935'}
	if(status === 'DENIED'){return '#e53935'}
	if(status === 'CANCEL'){return '#e53935'}
	if(status === 'REJECTED'){return '#e53935'}
	if(status === 'refunded'){return '#43A047'}
	if(status === 'failed'){return '#e53935'}
	if(status === 'processing'){return '#e53935'}
	if(status === 'queued'){return '#e53935'}
	if(status === 'opgehaald'){return '#43A047'}
	if(status === 'geannuleerd'){return '#e53935'}
	if(status === 'ophalen'){return '#e53935'}
	if(status === 'bereiden'){return '#e53935'}
	if(status === 'partypas'){return '#43A047'}
	if(status === 'returned'){return '#43A047'}
	if(status === 'headphonesReturned'){return '#43A047'}
	if(status === 'headphonesRented'){return '#43A047'}
}

addLocker(){
	var lockerPeriod = this.getLockerDates()[this.state.addLockerPeriod];
	var locker = lockerPeriod.lockers[this.state.addLockerInd];
	var lockerObj = {aantal: 1, id: locker.id, kosten: parseFloat(locker.price.replace(",", ".")), fetched: false, start: lockerPeriod.start, end: lockerPeriod.end, desc: locker.desc, supplier: locker.supplier};
	if(locker.keynius === true){lockerObj['keynius'] = true}
	if(locker.lockerCompany && locker.lockerCompany.connect){lockerObj['lockerCompany'] = locker.lockerCompany}
	db.collection("events").doc(this.props.observableStore.currEvent.ID).collection("users").doc(this.state.userID).collection("lockers").doc(Math.random().toString(36).slice(2)).set(lockerObj);
	this.toggleLockerModal();
}

getLockerDates(){
	var lockerDates = [];
	if(this.props.observableStore.currEvent.kluizen){
		lockerDates = this.props.observableStore.currEvent.kluizen.sort((a, b) => a.start - b.start);
	}
	return lockerDates;
}

formatDate(epoch){
	var date = new Date(0);
	date.setUTCSeconds(parseFloat(epoch));
	return ("0" + date.getDate()).slice(-2) + '-' + ("0" + (date.getMonth() +1)).slice(-2) + '-' + date.getFullYear();		
}


}

export default Barren;